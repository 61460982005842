import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";

export default function Services() {


  


  return (
    <>
    
    <Helmet>
				<meta title="Tudo Technologies - Best Mobile App and Website Development Company in Bangalore India with 15 years of experience in web Android and iOS App Development. "
        name="description"
				content="IT Services Company in Bangalore, India, Software companies in Bangalore, iOS/Android development, Software Development, UI/UX Design, D2C app development, Platform development.
				We are dominant IT services company in Bangalore. Which acts as a technology associate partner for you. Avail our custom web development, app solution, iOS/Android Developments and digital marketing solutions that reach to your audience.
				Tudo Technologies is an IT service provider recognized globally as a technology expert with offices in Toronto, Canada, San Francisco, California, USA, Sydney, Australia, Auckland, New Zealand, UAE & Bangalore, India. 
				We offer services in key areas of enterprise mobile app development, customer relationship management (CRM), enterprise content management solutions(CMS), DevOps and software product engineering (SPE). Connect with us and let's discuss business!"
				/>
				<meta name="keywords" content="app development company, top app development companies, app development companies, top app development company, mobile app development company, mobile app development companies, bangalore, 
				bengaluru, dubai, lagos, Abu Dhabi, san francisco, toronto, auckland, sydney, glasgow, UK, USA, Canada, New Zealand, Australia, UAE, India, Nigeria, best app development companies, best app development company"/>
	  	</Helmet>

    <div className='fadeEntry'>
      <section className='services'>
        <div className='container' id='services'>
          <div className='col-lg-12' style={{ margin: "0 auto" }}>
            <div className='row d-flex align-items-center'>
              <div className='col-lg-6 d-flex flex-column justify-content-end'>
                <h1 className='services-title'>
                  Our Services<span className='cyan'>.</span>
                </h1>
                <p>
                  We work small, but we think big. We wrestle with business
                  challenges, discover new opportunities, and provide innovative
                  solutions that will help you define and refine your product
                  ideas, design them, develop web and mobile-app. Ensuring its
                  Scalable, Reliable, Easy Usable and Secure.{" "}
                </p>
              </div>
              <div className='col-lg-6 d-flex justify-content-end services-slider'>
                <div className='container'>
                  <div className='pagination' id='slick-wrapper'>
                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                        Full Stack Development
                      </span>
                      <p>
                        With the experience of building companies from the idea
                        stage to multiple rounds of funding. We will implement
                        all the development needs of your brand.
                      </p>
                    </div>
                    <div className='slick-item'>
                      <span className='pagination-item | animate'>UX/UI</span>
                      <p className='pb-lg-0 pb-4'>
                        We will create the best user experience for applications
                        and run across multiple levels of user validations to
                        ensure the end-user has the best experience of your
                        platform.
                      </p>
                    </div>
                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                        Backend Development
                      </span>
                      <p>
                        With over 50 years of cumulative experience in backend
                        development for multiple organizations. We will develop
                        the backend structure strongly focused on flexibility,
                        scalability, and security.
                      </p>
                    </div>
                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                        Frontend Development
                      </span>
                      <p>
                        Looking for a team that integrates your backend business
                        logic into user-centric platforms? We will help you
                        create the right designs, implement the APIs and take
                        your dream to users.
                      </p>
                    </div>
                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                        Hybrid App Development
                      </span>
                      <p>
                        Developing an app that does not feature intensive? We
                        develop apps using cross-platform frameworks like
                        include Flutter, React, Ionic and Xamarin.
                      </p>
                    </div>

                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                        Native App Development
                      </span>
                      <p>
                        Hybrid app development doesn't fit your need? We build
                        the app on native Android and iOS to ensure your app can
                        provide the best user experience.
                      </p>
                    </div>

                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                      Automation Testing 
                      </span>
                      <p>
                      Efficient web and mobile test automation for enhanced software quality and rapid release cycles
                      </p>
                    </div>

                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                      Manual Testing
                      </span>
                      <p>
                      Guarantee software reliability and user satisfaction through comprehensive testing, including rigorous regression scenarios.
                      </p>
                    </div>

                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                      RPA
                      </span>
                      <p>
                      Robotic Process Automation (RPA) deploys software bots to automate repetitive, rule-based tasks in business processes, enhancing efficiency and reducing human intervention.
                      </p>
                    </div>

                    <div className='slick-item'>
                      <span className='pagination-item | animate'>
                      BPA
                      </span>
                      <p>
                      Business Process Automation (BPA) optimizes workflows by integrating technology, with RPA for efficiency, aiming to improve business performance.

                      </p>
                    </div>

                    {/* <div className='slick-item'>
                      <span className='pagination-item | animate'>
                        Ideation
                      </span>
                      <p className='pb-lg-2 pb-5'>
                        We believe in LEAN product development. We ideate with
                        you to ensure you have a scalable product with the most
                        essential functionalities.
                      </p>
                    </div> */}
                   



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--=========== End Services Section ===========--> */}
    </div>
    </>
  );
}