import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import useWindowResize from "../../CustomHooks/useWindowResize";
import MobileNav from "./MobileNav/MobileNav";
import { Helmet } from "react-helmet";

import "./NavBar.css";
import { logEvent, getAnalytics } from "firebase/analytics";

export default function NavBar() {

	const analytics = getAnalytics()


	const HandleMobileNav = () => {
		console.log(document.getElementById("mobileNav"));
		document.getElementById("mobileNav").classList.toggle("d-none");
	};


	const handleEvents = (link) => {
		logEvent(analytics,`Clicked on ${link}`)
	}


	const { width } = useWindowResize();
	return (
		<>
			<Helmet>
				<meta title="Tudo Technologies - Best Mobile App and Website Development Company in Bangalore India with 15 years of experience in web Android and iOS App Development. "
        name="description"
				content="IT Services Company in Bangalore, India, Software companies in Bangalore, iOS/Android development, Software Development, UI/UX Design, D2C app development, Platform development.
				We are dominant IT services company in Bangalore. Which acts as a technology associate partner for you. Avail our custom web development, app solution, iOS/Android Developments and digital marketing solutions that reach to your audience.
				Tudo Technologies is an IT service provider recognized globally as a technology expert with offices in Toronto, Canada, San Francisco, California, USA, Sydney, Australia, Auckland, New Zealand, UAE & Bangalore, India. 
				We offer services in key areas of enterprise mobile app development, customer relationship management (CRM), enterprise content management solutions(CMS), DevOps and software product engineering (SPE). Connect with us and let's discuss business!"
				/>
				<meta name="keywords" content="app development company, top app development companies, app development companies, top app development company, mobile app development company, mobile app development companies, bangalore, 
				bengaluru, dubai, lagos, Abu Dhabi, san francisco, toronto, auckland, sydney, glasgow, UK, USA, Canada, New Zealand, Australia, UAE, India, Nigeria, best app development companies, best app development company"/>
	  	</Helmet>
		

		<header id='header' className='d-flex align-items-center'>
			<div className='container d-flex align-items-center'>
				<Link to='/' className='logo me-auto'>
					<img
						src='/assets/img/logo.png'
						alt=''
						className='img-fluid'
					/>
				</Link>
				{width <= 750 ? (
					<span
						onClick={() => HandleMobileNav()}
						style={{ cursor: "pointer" }}>
						<i className='fas fa-bars fa-2x' />
					</span>
				) : (
					<div className='navLinks'>
						<Link className='me-md-5 me-3' to='/#services' onClick={()=>handleEvents("Services")}>
							Services
						</Link>
						{/* <Link className='me-5' to='/ourworks'>
							Our Works
						</Link> */}
						<Link className='me-5' to='/ourworksv2' onClick={()=>handleEvents("Our Works")}>
							Our Works
						</Link>
						<HashLink className='me-5' to='/#blogs' onClick={()=>handleEvents("Blogs")}>
							Blogs
						</HashLink>
						<HashLink className='me-5' to='/#contact' onClick={()=>handleEvents("Contact")}>
							Contact
						</HashLink>
					</div>
				)}
			</div>
			<MobileNav />
		</header>
		</>
	);
}
