import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import NavBar from "../Head/NavBar";
import ImageCarousel from "./ImageCarousel";
import "./IndividualWorks.css";
import MoreFromUs from "./MoreFromUs";
import { Helmet } from "react-helmet";
import { getAnalytics, logEvent } from "firebase/analytics";

export default function IndividualWorks() {
  const { id } = useParams();
  const location = useLocation();

  const [ourworksData, setOurworksData] = useState([]);
  const analytics = getAnalytics()

  useEffect(() => {
    fetch("/ourworks.json")
      .then(async (res) => await res.json())
      .then((result) => {
        console.log(result);
        result = result.filter((item) => item.id === id);
        setOurworksData(result[0]);
      });
    window.scrollTo(0, 0);
  }, [location]);



  if (Object.keys(ourworksData).length) {
    console.log(ourworksData);
    const {
      company,
      website,
      aboutCompany,
      serviceProvided,
      latestAchievement,
      images,
      links
    } = ourworksData;


    const handleViewWebsite = (name) => {
      logEvent(analytics,`Clicked on view website for ${name}`)
    }
   

    return (
      <>
    <Helmet>
				<meta title="Tudo Technologies - Best Mobile App and Website Development Company in Bangalore India with 15 years of experience in web Android and iOS App Development. "
        name="description"
				content="IT Services Company in Bangalore, India, Software companies in Bangalore, iOS/Android development, Software Development, UI/UX Design, D2C app development, Platform development.
				We are dominant IT services company in Bangalore. Which acts as a technology associate partner for you. Avail our custom web development, app solution, iOS/Android Developments and digital marketing solutions that reach to your audience.
				Tudo Technologies is an IT service provider recognized globally as a technology expert with offices in Toronto, Canada, San Francisco, California, USA, Sydney, Australia, Auckland, New Zealand, UAE & Bangalore, India. 
				We offer services in key areas of enterprise mobile app development, customer relationship management (CRM), enterprise content management solutions(CMS), DevOps and software product engineering (SPE). Connect with us and let's discuss business!"
				/>
				<meta name="keywords" content="app development company, top app development companies, app development companies, top app development company, mobile app development company, mobile app development companies, bangalore, 
				bengaluru, dubai, lagos, Abu Dhabi, san francisco, toronto, auckland, sydney, glasgow, UK, USA, Canada, New Zealand, Australia, UAE, India, Nigeria, best app development companies, best app development company"/>
	  	</Helmet>

      
        <NavBar />

        <img className='gradientDesign' src="/assets/img/gradientDesign.png" alt="" />

        <div className='container individualWorks'>
          <nav aria-label='breadcrumb'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to='/ourworksv2'>Our Works</Link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                {company}
              </li>
            </ol>
          </nav>
          <div className='d-flex flex-wrap '>
            <div className='col-md-7 col-12'>
              <h1 className='fw-bold mb-5 comp_name'>{company}</h1>
              {
                company == "Namak" ?
                aboutCompany.map((item) => (
                  <>
                    <h2 className="aboutComp_h2">{item.title}</h2>
                    <p>{item.descrip}</p>
                  </>
                ))
                :
                <p className='fw-bold'>{aboutCompany}</p>
              }
              
            </div>
            <div className='col-md-5 col-12 text-md-end'>
              <h3 className='fw-bold mb-3' >SERVICES PROVIDED</h3>
              <div>
                <ul className='fw-bold p-0 ' style={{ listStyle: "none" }}>
                  {serviceProvided.map((item) => (
                    <li className='my-2'>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>


          {/* <div className='my-5 '>
              
              {
                links && links.map((item) => (
                  
                  <p>{item.title !== "Visit Website"}</p>
                ))
              }

          </div> */}


          {
            links && links.length > 0 && 
            <div className="mt-5 overview">
              <h3>Overview /</h3>
            </div>
          }

          

          <div className="d-flex" style={{columnGap:"30px"}}>
            
          {
            
            links.length > 0 ? links.map((item) => (

              <div className='mt-1 product-links'>
              <a
                onClick={()=>handleViewWebsite(company)}
                className='d-inline-flex justify-content-start align-items-center'
                href={item.link}
                target='_blank'
                rel='noopener noreferrer'>
                <span className='website me-3' >{item.title}</span>
                <span>
                  <img
                    className='img-fluid'
                    src='/assets/img/linkArrow.png'
                    alt=''
                    srcset=''
                  />
                </span>
              </a>
            </div>
            )) 
            

            :

            <div className='mt-5 '>
              <span className='website me-3' >Overview</span>
            </div>
            
          }
          </div>

          


          <div>
            <ImageCarousel images={images} id={id}/>
          </div>


          {latestAchievement["text"].length > 0 && <div className='latestAchievement'>
            <h6 className='title'>LATEST ACHIEVEMENTS</h6>
            <div className='latestAchivement my-5 d-flex justify-content-between align-items-center'>
              <div className='col-md-6 col-12 achievement-text pe-2'>
                <p>{latestAchievement["text"]}</p>
              </div>
              {latestAchievement["investment"] ? (
                <div className='company-investment col-md-3 col-12 text-center d-flex flex-column justify-content-center align-items-center'>
                  <span className='investment'>
                    {latestAchievement["investment"]}
                  </span>
                  <h6 className='text-white fw-bold'>Investment</h6>
                </div>
              ) : (
                ""
              )}
              {latestAchievement["cities"] ? (
                <div className='company-cities col-md-3 col-12 text-center  d-flex flex-column justify-content-center align-items-center'>
                  <span className='cities'>{latestAchievement["cities"]}</span>
                  <h6 className='text-white fw-bold'> Cities </h6>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>}



          <div className='MoreFromUs'>
            <h4 className='title'>More from us</h4>
            <div className='my-5'>
              <MoreFromUs id={id} />
              <div
                className='d-flex justify-content-center align-items-center my-5'
                style={{ zIndex: 50 }}>
                <span
                  className='MoreFromUs-prev-btn'
                  style={{ cursor: "pointer" }}>
                  <img
                    className=''
                    src='/assets/img/Arrow 5.png'
                    alt=''
                    srcset=''
                  />
                </span>
                <span
                  className='MoreFromUs-next-btn mx-3'
                  style={{ cursor: "pointer" }}>
                  <img
                    className='img-fluid'
                    src='/assets/img/Arrow 6.png'
                    alt=''
                    srcset=''
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <div></div>;
  }
}
