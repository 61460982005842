import React from 'react'
import "./ourAssociations.css"

const OurAssociations = () => {
  return (
    <div className="ourAsscociationContainer">
        <div className='container OurAssociations'>
            <h3>Our Associations</h3>
            <div className="AssociationIconsContainer">
                <div className="AssociationContainer">
                    <img src="/assets/associations/tieGlobal.png" alt="" />
                    <img src="/assets/associations/founderInstitute.png" alt="" />
                    <img src="/assets/associations/sritIcon.svg" alt="" />
                </div>
                <div className="AssociationContainer2">
                    <img className='fusebo' src="/assets/associations/fusebo.svg" alt="" />
                    <img className='niod' src="/assets/associations/niodIcon.svg" alt="" />
                </div>
            </div>
        </div>  
    </div>
  )
}

export default OurAssociations