import React, { useState } from 'react'
import "./services.css"

const Servicesv2 = () => {

    const services = [
        {
            img:"/assets/img/services/fullstack.svg",
            title:"Full Stack Development",
            descrip:"With the experience of building companies from the idea stage to multiple rounds of funding. We will implement all the development needs of your brand."
    
        },
        {
            img:"/assets/img/services/uiux.svg",
            title:"UX/UI",
            descrip:"We will create the best user experience for applications and run across multiple levels of user validations to ensure the end-user has the best experience of your platform."
    
        },
        {
            img:"/assets/img/services/backend.svg",
            title:"Backend Development",
            descrip:"With over 50 years of cumulative experience in backend development for multiple organizations. We will develop the backend structure strongly focused on flexibility, scalability, and security."
    
        },
        {
            img:"/assets/img/services/frontend.svg",
            title:"Frontend Development",
            descrip:"Looking for a team that integrates your backend business logic into user-centric platforms? We will help you create the right designs, implement the APIs and take your dream to users."
    
        },
        {
            img:"/assets/img/services/hybrid.svg",
            title:"Hybrid App Development",
            descrip:"Developing an app that does not feature intensive? We develop apps using cross-platform frameworks likeinclude Flutter, React, Ionic and Xamarin."
        },

        {
            img:"/assets/img/services/automation.svg",
            title:"Automation Testing ",
            descrip:"Efficient web and mobile test automation for enhanced software quality and rapid release cycles."
        },

        {
            img:"/assets/img/services/manual.svg",
            title:"Manual Testing",
            descrip:"Guarantee software reliability and user satisfaction through comprehensive testing, including rigorous regression scenarios."
        },

        {
            img:"/assets/img/services/rpa.svg",
            title:"RPA",
            descrip:"Robotic Process Automation (RPA) deploys software bots to automate repetitive, rule-based tasks in business processes, enhancing efficiency and reducing human intervention."
        },

        {
            img:"/assets/img/services/bpa.svg",
            title:"BPA",
            descrip:"Business Process Automation (BPA) optimizes workflows by integrating technology, with RPA for efficiency, aiming to improve business performance."
        },
    ] 

    const [activeCard,setActiveCard] = useState(null)
    const handleCardClick = (id) => {
        setActiveCard(id)
    }

  return (
    <div className='Servicesv2'>

       {/* <div className="services-gradient">
            <img src="/assets/img/service-gradient.png" alt="" />
        </div>  */}

        <div className='Servicesv2_container container'>

            <h1 className='services-title'>
                Our Services<span className='cyan'>.</span>
            </h1>
            <p className='service-para'>
                We work small, but we think big. We wrestle with business
                challenges, discover new opportunities, and provide innovative
                solutions that will help you define and refine your product
                ideas, design them, develop web and mobile-app. Ensuring its
                Scalable, Reliable, Easy Usable and Secure.{" "}
            </p>


            <div className="servicesv2_card_container">

                {
                    services.map((item,id) => (
                        <div className="servicesv2_card" 
                        style={activeCard == id ? {background:"rgba(149, 149, 149, 0.2)", padding:"24px 32px"} : {background:"none"}}
                        onClick={()=>handleCardClick(id)}>

                            {/* <div className='service_card_background'></div> */}
                            <div className='service-icon'>
                                <img src={item.img} alt="" />
                            </div>
                            
                            <h3>{item.title}</h3>
                            <p>{item.descrip}</p>
                        </div>
                    ))
                }

            </div>


        </div>
    </div>
  )
}

export default Servicesv2