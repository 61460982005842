import React from 'react'
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
// import "./services.css"
import { Navigation } from "swiper";
import "swiper/css/navigation";
import "./offerings.css"

const Offerings = () => {

  const offerings = [
    {
      img:"/assets/img/offerings/time.svg",
        title:"Time and Materials Contract",
        descrip:"Flexible billing based on actual hours and materials used, offering adaptability for evolving project needs."

    },
    {
      img:"/assets/img/offerings/fixedprice.svg",
        title:"Fixed Price Contract",
        descrip:"Predetermined cost for a specific scope of work, providing budget predictability and financial clarity."

    },
    {
      img:"/assets/img/offerings/offshore.svg",
        title:"Offshore Development Center",
        descrip:"Establish a dedicated remote team for comprehensive development services and collaborative project management."

    },
    {
      img:"/assets/img/offerings/resource.svg",
        title:"Resource Augmentation",
        descrip:"On-demand skilled professionals to supplement existing teams, ensuring project scalability and success."

    },
    {
      img:"/assets/img/offerings/increment.svg",
        title:"Incremental or Milestone-based Contract",
        descrip:"Payment tied to project milestones, ensuring alignment of progress with financial commitments."
    },
    {
      img:"/assets/img/offerings/revenue.png",
      title:"Revenue and Royalty Sharing Agreement",
      descrip:"A collaborative model where compensation is tied to project success and revenue outcomes. Aligning incentives for sustained success and profitability."
    },
  ]   


  const handleChangeSlide = () => {

  }

  return (
    <>
    
    <Helmet>
				<meta title="Tudo Technologies - Best Mobile App and Website Development Company in Bangalore India with 15 years of experience in web Android and iOS App Development. "
        name="description"
				content="IT Services Company in Bangalore, India, Software companies in Bangalore, iOS/Android development, Software Development, UI/UX Design, D2C app development, Platform development.
				We are dominant IT services company in Bangalore. Which acts as a technology associate partner for you. Avail our custom web development, app solution, iOS/Android Developments and digital marketing solutions that reach to your audience.
				Tudo Technologies is an IT service provider recognized globally as a technology expert with offices in Toronto, Canada, San Francisco, California, USA, Sydney, Australia, Auckland, New Zealand, UAE & Bangalore, India. 
				We offer services in key areas of enterprise mobile app development, customer relationship management (CRM), enterprise content management solutions(CMS), DevOps and software product engineering (SPE). Connect with us and let's discuss business!"
				/>
				<meta name="keywords" content="app development company, top app development companies, app development companies, top app development company, mobile app development company, mobile app development companies, bangalore, 
				bengaluru, dubai, lagos, Abu Dhabi, san francisco, toronto, auckland, sydney, glasgow, UK, USA, Canada, New Zealand, Australia, UAE, India, Nigeria, best app development companies, best app development company"/>
	  	</Helmet>

    <div className='fadeEntry'>

      <section className='offerings'>

        {/* <div className='offering-gradient'>
          <img src="/assets/img/service-gradient.png" alt="" />
        </div> */}

        <div className='offerings_container container'>
          <h1 className='services-title'>
              Offerings<span className='cyan'>.</span>
          </h1>
          <p className='service-para'>
            Empowering client success, we offer tailored solutions meeting project-specific needs. Whether augmenting resources, establishing offshore development centres, or exploring revenue-sharing models, our offerings are crafted for easy development and client success.{" "}
          </p>


          <div className="offerings-card-container">

            <div className='arrow-container'>
              <img className='offers-prev-icon' src="assets/img/Arrow 5.png" alt="" />
              <img className='offers-next-icon' src="assets/img/Arrow 5.png" alt="" />
            </div>

            <Swiper
                  modules={[Navigation]}
                  spaceBetween={30}
                  slidesPerView={"auto"}
                  // centeredSlides={true}
                  onSlideChange={(swiper) => handleChangeSlide(swiper)}
                  onSwiper={(swiper) => console.log(swiper.activeIndex)}
                  loop={true}
                  slideToClickedSlide={true}
                  navigation={{
                    prevEl: ".offers-prev-icon",
                    nextEl: ".offers-next-icon",
                  }}
                  breakpoints={{
                    1400:{
                      spaceBetween:30
                    },
                    1300:{
                      spaceBetween:30
                    },
                    600:{
                      spaceBetween:20
                    },
                    400:{
                      spaceBetween:20
                    },
                    300:{
                      spaceBetween:20
                    }
                  }}
                  
                >
                  {
                    offerings.map((item) => (
                      <SwiperSlide>
                        <>
                        <div className='offering-card-background'>

                        </div>
                        <div className='offering-card'>
                          <div className='offering-icon'>
                            <img src={item.img} alt="" />
                          </div>
                          
                          <h3>{item.title}</h3>
                          <p>{item.descrip}</p>
                        </div>
                        </>
                      </SwiperSlide>
                    ))
                  }
              
                </Swiper>
          </div>


        </div>
      </section>
      
      
    </div>
    </>
  )
}

export default Offerings