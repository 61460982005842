import React, { useEffect, useState } from "react";
import "./Blogs.css";
import { Navigation } from "swiper";
import {Helmet} from "react-helmet"

import { Swiper, SwiperSlide, SwiperCore } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import BlogsCard from "./BlogsCard";
import useWindowResize from "../../CustomHooks/useWindowResize";
import { getAnalytics, logEvent } from "firebase/analytics";

export default function Blogs() {
	const [slideData, setSlideData] = useState([]);
	const [page, setPage] = useState(1);
	const [TotalPage, setTotalPage] = useState(slideData.length - 1);
	const { width } = useWindowResize();


	const analytics = getAnalytics()

	useEffect(() => {
		fetch("/blogs.json")
			.then((res) => res.json())
			.then((result) => setSlideData(result));
		width <= 720
			? setTotalPage(slideData.length)
			: setTotalPage(slideData.length - 1);
	}, [slideData]);




	const handleBlogEvents = (blog) => {
		console.log(blog.title)
		logEvent(analytics,`Clicked on ${blog.title} blog`)
	}

	// const handleNext = () => {
	// 	if (page === TotalPage) {
	// 		return;
	// 	}
	// 	setPage(page + 1);
	// };
	// const handlePrev = () => {
	// 	if (page === 1) {
	// 		return;
	// 	}
	// 	setPage(page - 1);
	// };
	return (
		<>
			<Helmet>
				<meta title="Tudo Technologies - Best Mobile App and Website Development Company in Bangalore India with 15 years of experience in web Android and iOS App Development. "
        		name="description"
				content="IT Services Company in Bangalore, India, Software companies in Bangalore, iOS/Android development, Software Development, UI/UX Design, D2C app development, Platform development.
				We are dominant IT services company in Bangalore. Which acts as a technology associate partner for you. Avail our custom web development, app solution, iOS/Android Developments and digital marketing solutions that reach to your audience.
				Tudo Technologies is an IT service provider recognized globally as a technology expert with offices in Toronto, Canada, San Francisco, California, USA, Sydney, Australia, Auckland, New Zealand, UAE & Bangalore, India. 
				We offer services in key areas of enterprise mobile app development, customer relationship management (CRM), enterprise content management solutions(CMS), DevOps and software product engineering (SPE). Connect with us and let's discuss business!"
				/>
				<meta name="keywords" content="app development company, top app development companies, app development companies, top app development company, mobile app development company, mobile app development companies, bangalore, 
				bengaluru, dubai, lagos, Abu Dhabi, san francisco, toronto, auckland, sydney, glasgow, UK, USA, Canada, New Zealand, Australia, UAE, India, Nigeria, best app development companies, best app development company"/>
	  	</Helmet>

		<div className='fadeEntry' id='blogs' style={{ minHeight: "300px" }}>
			<div className='container my-5'>
				<div className='blogs py-5 d-flex justify-content-between'>
					<h1 className=''>
						Blogs<span className='cyan'>.</span>
					</h1>
				</div>
				<div className='d-flex flex-wrap'>
					<div className='col-12 col-md-3 pe-4 mb-5'>
						<h4>
							We bring information regarding latest trends in the
							market on the table in our blogs.
						</h4>
						<div className='mt-5'>
							<p>View all of our blogs on our Medium Page.</p>
							<a
								href='https://tudotechnologies.medium.com'
								target='_blank'
								rel='noopener noreferrer'
								className='d-inline-block'>
								<span className='d-flex align-items-center'>
									<img
										style={{ width: "2rem" }}
										className='img-fluid me-2'
										src='/assets/img/medium.png'
										alt=''
										srcset=''
									/>
									<span> /tudotechnologies</span>
								</span>
							</a>
						</div>
					</div>
					<div
						className='col-12 col-md-9 '
						style={{ minHeight: "300px" }}>
						{/* // import Swiper core and required modules */}
						<Swiper
							// install Swiper modules
							id='swipperBlog'
							modules={[Navigation]}
							spaceBetween={15}
							slidesPerView={2}
							onSlideChange={(swiper) =>
								setPage(swiper.realIndex + 1)
							}
							breakpoints={{
								320: {
									slidesPerView: 1,
									spaceBetween: 10,
								},
								600: {
									slidesPerView: 2,
									spaceBetween: 10,
								},
							}}
							navigation={{
								nextEl: ".blogs-next-btn",
								prevEl: ".blogs-prev-btn",
							}}>
							{slideData.map((item, index) => (
								<SwiperSlide key={index} onClick={()=>handleBlogEvents(item)}>
									<BlogsCard key={index} item={item} />
								</SwiperSlide>
							))}
						</Swiper>
						<div
							className='d-flex justify-content-md-end align-items-center my-md-3'
							style={{ zIndex: 50 }}>
							<span
								// onClick={handlePrev}
								className='blogs-prev-btn'
								style={{ cursor: "pointer" }}>
								<img
									className=''
									src='/assets/img/Arrow 5.png'
									alt=''
									srcset=''
								/>
							</span>
							<span
								// onClick={handleNext}
								className='blogs-next-btn mx-3'
								style={{ cursor: "pointer" }}>
								<img
									className='img-fluid'
									src='/assets/img/Arrow 6.png'
									alt=''
									srcset=''
								/>
							</span>
							<span className='blogs-pagination'>
								{page} / {TotalPage}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	);
}
