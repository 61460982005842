import React from "react";
import { Helmet } from "react-helmet";

export default function BlogsCard({ item }) {
	const { image, title, link, date, tag, min } = item;
	// console.log(item);
	return (
		<>
			<Helmet>
				<meta title="Tudo Technologies - Best Mobile App and Website Development Company in Bangalore India with 15 years of experience in web Android and iOS App Development. "
        		name="description"
				content="IT Services Company in Bangalore, India, Software companies in Bangalore, iOS/Android development, Software Development, UI/UX Design, D2C app development, Platform development.
				We are dominant IT services company in Bangalore. Which acts as a technology associate partner for you. Avail our custom web development, app solution, iOS/Android Developments and digital marketing solutions that reach to your audience.
				Tudo Technologies is an IT service provider recognized globally as a technology expert with offices in Toronto, Canada, San Francisco, California, USA, Sydney, Australia, Auckland, New Zealand, UAE & Bangalore, India. 
				We offer services in key areas of enterprise mobile app development, customer relationship management (CRM), enterprise content management solutions(CMS), DevOps and software product engineering (SPE). Connect with us and let's discuss business!"
				/>
				<meta name="keywords" content="app development company, top app development companies, app development companies, top app development company, mobile app development company, mobile app development companies, bangalore, 
				bengaluru, dubai, lagos, Abu Dhabi, san francisco, toronto, auckland, sydney, glasgow, UK, USA, Canada, New Zealand, Australia, UAE, India, Nigeria, best app development companies, best app development company"/>
	  	</Helmet>
		<div>
			<div
				className='position-relative overlayImage rounded'
				style={{
					background: "transparent",
					
				}}>
				<img
					style={{
						aspectRatio: "1/1",
						borderRadius: "10px",
						height: "100%",
						width: "100%",
					}}
					className='img-fluid'
					src={image}
					alt=''
				/>
				<div
					className='position-absolute p-3 w-100'
					style={{
						left: 0,
						bottom: 0,
						zIndex: 50,
					}}>
					<p className='tag m-0 mb-2'>{tag}</p>
					<p className='date'>{date}</p>
					<h3
						className='fw-bold'
						style={{ fontSize: "1.3rem", lineHeight: "2rem" }}>
						{title}
					</h3>
					<div className='d-flex justify-content-between'>
						<span className='d-flex align-items-center'>
							<img
								src='/assets/img/timeToread.png'
								alt=''
								className='img-fluid'
								style={{ width: "1rem" }}
							/>
							<span
								className='ms-2'
								style={{ fontSize: ".8rem" }}>
								{min} read
							</span>
						</span>
						<span className='d-flex align-items-center'>
							<a
								href={link}
								target='_blank'
								rel='noopener noreferrer'>
								<span
									className='me-2'
									style={{ fontSize: ".8rem" }}>
									Read More
								</span>
								<img
									style={{ width: "3rem" }}
									src='/assets/img/Arrow 6.png'
									alt=''
									className='img-fluid'
								/>
							</a>
						</span>
					</div>
				</div>
			</div>
		</div>
		</>
	);
}
