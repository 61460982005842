import React from "react";
import {Helmet} from "react-helmet"
// import DocumentMeta from "react-document-meta";

export default function Approach() {

  // const meta = {
  //   title: "Tudo Technologies - Best Mobile App and Website Development Company in Bangalore India with 15 years of experience in web Android and iOS App Development",
  //   description: "IT Services Company in Bangalore, India, Software companies in Bangalore, iOS/Android development, Software Development, UI/UX Design, D2C app development, Platform developmentWe are dominant IT services company in Bangalore. Which acts as a technology associate partner for you. Avail our custom web development, app solution, iOS/Android Developments and digital marketing solutions that reach to your audience.Tudo Technologies is an IT service provider recognized globally as a technology expert with offices in Toronto, Canada, San Francisco, California, USA, Sydney, Australia, Auckland, New Zealand, UAE & Bangalore, India. We offer services in key areas of enterprise mobile app development, customer relationship management (CRM), enterprise content management solutions(CMS), DevOps and software product engineering (SPE). Connect with us and let's discuss business!",
  //   meta: {
  //     charset: "utf-8",
  //     name: {
  //       keywords: "app development company, top app development companies, app development companies, top app development company, mobile app development company, mobile app development companies, bangalore, bengaluru, dubai, lagos, Abu Dhabi, san francisco, toronto, auckland, sydney, glasgow, UK, USA, Canada, New Zealand, Australia, UAE, India, Nigeria, best app development companies, best app development company"
  //     }
  //   }
  // };

  return (
    <>
      <Helmet>
				<meta title="Tudo Technologies - Best Mobile App and Website Development Company in Bangalore India with 15 years of experience in web Android and iOS App Development. "
        name="description"
				content="IT Services Company in Bangalore, India, Software companies in Bangalore, iOS/Android development, Software Development, UI/UX Design, D2C app development, Platform development.
				We are dominant IT services company in Bangalore. Which acts as a technology associate partner for you. Avail our custom web development, app solution, iOS/Android Developments and digital marketing solutions that reach to your audience.
				Tudo Technologies is an IT service provider recognized globally as a technology expert with offices in Toronto, Canada, San Francisco, California, USA, Sydney, Australia, Auckland, New Zealand, UAE & Bangalore, India. 
				We offer services in key areas of enterprise mobile app development, customer relationship management (CRM), enterprise content management solutions(CMS), DevOps and software product engineering (SPE). Connect with us and let's discuss business!"
				/>
				<meta name="keywords" content="app development company, top app development companies, app development companies, top app development company, mobile app development company, mobile app development companies, bangalore, 
				bengaluru, dubai, lagos, Abu Dhabi, san francisco, toronto, auckland, sydney, glasgow, UK, USA, Canada, New Zealand, Australia, UAE, India, Nigeria, best app development companies, best app development company"/>
	  	</Helmet>

   
    <div className="fadeEntry animate__animated ">
      {/* <!--=========== Approach Section ===========--> */}
      <section className="approach ">
        <div className="container">
          <div className="col-lg-12" style={{ margin: "0 auto" }}>
            <div className="row">
              <div className="col-lg-4 d-flex justify-content-center flex-column">
                <h1 className="mb-4">
                  Our Approach<span className="cyan">.</span>
                </h1>
                <p>
                  We built our solutions by closely listening to our potential
                  clientele and understanding their expectations. We know how to
                  analyze this information and customize our offering to
                  changing market needs. Know Our Step by Step Approach.
                </p>
              </div>
              <div className="col-lg-8 approach-image">
                <object
                  type="image/svg+xml"
                  data="assets/img/approach_tudo.svg"
                ></object>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--=========== End Approach Section ===========--> */}
    </div>
    </>
  );
}
