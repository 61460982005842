import React from "react";
import "./OurTeam.css";
import { Helmet } from "react-helmet";

export default function OurTeam() {
	const handleGradientEnter = () => {
		const el = document.getElementsByClassName("gradient")[0];
		el.classList.remove("gradient-active");
		// console.log(document.getElementsByClassName("gradient")[0]);
	};
	const handleGradientLeave = () => {
		const el = document.getElementsByClassName("gradient")[0];
		el.classList.add("gradient-active");
		// console.log(document.getElementsByClassName("gradient")[0]);
	};
	return (
		<>
			<Helmet>
				<meta title="Tudo Technologies - Best Mobile App and Website Development Company in Bangalore India with 15 years of experience in web Android and iOS App Development. "
        name="description"
				content="IT Services Company in Bangalore, India, Software companies in Bangalore, iOS/Android development, Software Development, UI/UX Design, D2C app development, Platform development.
				We are dominant IT services company in Bangalore. Which acts as a technology associate partner for you. Avail our custom web development, app solution, iOS/Android Developments and digital marketing solutions that reach to your audience.
				Tudo Technologies is an IT service provider recognized globally as a technology expert with offices in Toronto, Canada, San Francisco, California, USA, Sydney, Australia, Auckland, New Zealand, UAE & Bangalore, India. 
				We offer services in key areas of enterprise mobile app development, customer relationship management (CRM), enterprise content management solutions(CMS), DevOps and software product engineering (SPE). Connect with us and let's discuss business!"
				/>
				<meta name="keywords" content="app development company, top app development companies, app development companies, top app development company, mobile app development company, mobile app development companies, bangalore, 
				bengaluru, dubai, lagos, Abu Dhabi, san francisco, toronto, auckland, sydney, glasgow, UK, USA, Canada, New Zealand, Australia, UAE, India, Nigeria, best app development companies, best app development company"/>
	  	</Helmet>

		<section className='fadeEntry animate__animated fadded '>
			<div className='d-flex flex-wrap container my-5'>
				<div className='col-md-6 col-12 d-flex flex-wrap  justify-content-evenly align-items-center'>
					<div className='ourTeam'>
						<h1 className=''>
							Our Team<span className='cyan'>.</span>
						</h1>
						<p className='mt-3'>
							Our team consist of dreamers and innovators coming
							from various multidisciplinary backgrounds. We
							provide different services.
						</p>
					</div>
				</div>
				<div
					className='col-md-6 col-12 boxes text-center'
					onMouseLeave={() => handleGradientLeave()}
					onMouseEnter={() => handleGradientEnter()}>
					<div className='row-1 d-flex justify-content-between justify-content-lg-evenly align-items-center my-4 '>
						<div className='d-flex justify-content-center align-items-center'>
							App <br /> Developers
						</div>
						<div className='d-flex justify-content-center align-items-center '>
							{/* Business <br /> Analysts */}
							Frontend <br/> Developers
						</div>
						<div className='d-flex justify-content-center align-items-center'>
							{/* Business <br /> Promoters */}
							Full-stack<br/> Developers
						</div>
					</div>
					<div className='row-1 d-flex  justify-content-between justify-content-lg-evenly  align-items-center my-4'>
						<div className='d-flex justify-content-center align-items-center'>
							{/* Content <br /> Writers */}
							Product <br/> Managers
						</div>
						<div className='d-flex justify-content-center align-items-center gradient gradient-active'>
							UI/UX <br /> Experts
						</div>
						<div className='d-flex justify-content-center align-items-center'>
							{/* Full-stack <br /> Developers */}
							Technical <br/> Architects
						</div>
					</div>
					<div className='row-1 d-flex  justify-content-between justify-content-lg-evenly  align-items-center my-4'>
						<div className='d-flex justify-content-center align-items-center'>
							{/* SEO <br /> Experts */}
							Backend <br/> Developers
						</div>
						<div className='d-flex justify-content-center align-items-center flex-column team-boxcard'>
							{/* Strategic <br /> Advisors */}
							Testers
							<span>( Automation & Manual )</span>
						</div>
						<div className='d-flex justify-content-center align-items-center'>
							{/* Technical <br /> Architects */}
							DevOps
						</div>
					</div>
				</div>
			</div>
		</section>
		</>
	);
}
