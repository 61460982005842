import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./ImageCarousel.css";

// import required modules
import { Pagination } from "swiper";
import { useLocation } from "react-router-dom";

export default function ImageCarousel({ images,id }) {
	// console.log(images);
	const [perView, setPerView] = useState("auto");
	const location = useLocation();
	useEffect(() => {
		setPerView("auto");
		console.log("REload")
	}, [location,images,id]);

	
	

	return (
		<div className='individualWorksImages my-5'>
			<Swiper
				init={true}
				observer={true}
				observeSlideChildren={true}
				observeParents={true}
				slidesPerView={perView}
				spaceBetween={30}
				pagination={{
					clickable: true,
				}}
				breakpoints={{
					
					320: {
						observer: true,
						slidesPerView: 1,
						observeSlideChildren: true,
						observeParents: true,
						spaceBetween: 10,
					},
					600: {
						observer: true,
						observeSlideChildren: true,
						observeParents: true,
						spaceBetween: 30,
					}
				}}
				modules={[Pagination]}
				className=''>
				{images.map((image,id) => (
					<SwiperSlide key={id}>
						<img
							className='img-fluid'
							src={image}
							alt=''
							srcset=''
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
}
