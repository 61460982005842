import React, { useState } from "react";
import {Helmet} from "react-helmet";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "./collaboration.css"
import { Navigation } from "swiper";
import "swiper/css/navigation";
import useWindowSize from "use-window-size-v2";
import { useHistory } from "react-router-dom";


export default function Collaboration() {

  const [slideArr,setSlideArr] = useState([])
  const [respSlideArr,setRespSlideArr] = useState([])
  const {width,height} = useWindowSize(0)

  const clients = [
    {
      slideId:1,
      name:"KPMG",
      img:"/assets/img/clients/kpmg.svg",
      content:"High efficiency in beat execution by each DSR (Direct Sales Representative) at retail outlets is crucial. We have designed a solution that empowers DSRs with a modern, intuitive, and elegant mobile application. With the objective to improve TCPL (Tata Consumer Products) product sales to retailers while ensuring better supervision by ASMs and high efficiency in beat execution by DSRs.",
      pageId:1
    },
    {
      slideId:2,
      name:"Artistic Yoga",
      img:"/assets/img/clients/ay.svg",
      content:"To ensure widespread access to a holistic approach for fitness and well-being, Tudo Technologies has digitized Artistic Yoga through web and app solutions. This enables yoga practitioners worldwide to easily connect and practice the Artistic Yoga method.",
      pageId:5
    },
    
    {
      slideId:3,
      name:"Vahan247",
      img:"/assets/img/clients/vahan1.svg",
      content:"Indian National Highways (NH) is a meager 5% of the country’s road network but accounts for 55% of the road accidents and 61% of the road deaths. Of 151,417 road-accident deaths recorded in 2018, 10% or 15,150 victims were drivers or passengers in trucks. With the aim of making Truck driving a safer carrer we provided a proactive solution to address the accidents and provide aid to the drivers at the earliest. Accredited by the MORTH (Ministry of Road Transport and Highways). The solution also address many other operational issues observed by fleet management companies.",
      pageId:9
      
    },
    {
      slideId:4,
      name:"Challo",
      img:"/assets/img/clients/clientv2_5.png",
      content:"Challo is a commuter service that allows you to arrange your travel in a more cost-effective and time-efficient manner by comparing various alternatives like metro, buses, various cab services, etc. under one platform.",
      pageId:8
    },
    {
      slideId:5,
      name:"Royal brothers",
      img:"/assets/img/clients/royalBrothers.svg",
      content:"Royal Brothers is a two-wheeler rental company with bikes ranging from Harley Davidson to Activa maintained to provide the best riding experience. Their end hassle-free booking experience and bike availability intelligence system gained a very huge customer following in its early days.",
      pageId:6
    },
    {
      slideId:6,
      name:"Let’s park",
      img:"/assets/img/clients/lp.svg",
      content:"Let's Park offers seamless parking management solutions through automation and optimization, including automatic number detection, vehicle movement tracking, boom barrier integration, and handheld POS-based easy billing and payment collection. All this is possible through IoT solutions provided by Tudo Technologies which enable hassle-free parking management. In the premium service Let’s Park also has a license plate-based number.",
      pageId:7
    },
    {
      slideId:7,
      name:"Geniusmesh",
      img:"/assets/img/clients/geniusmesh.svg",
      content:"GeniusMesh is an AI-powered recruiting technology platform built for executive-level hiring. By connecting leading employers, top business schools, and Executive MBA graduates on one multi-sided platform, GeniusMesh provides an alternative to expensive executive agency recruiters and facilitates hiring of global leadership roles faster than ever before. We ideated and designed the resume analysis, salary analysis, 2.0 version social app, employer and job portal. ",
      pageId:2
    },
  ]


  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleChangeSlide = (swiper) => {
    // Update the active slide content when the slide changes
    // setActiveSlideContent(clients[activeIndex]?.content);

    // if(width > 600){
    //   const currentIndex = swiper.activeIndex % clients.length;
    //   setActiveSlideIndex(currentIndex < 0 ? clients.length - 1 : currentIndex);
    // }
   
    // else{
    //   const currentIndex = swiper.activeIndex % clients.length;
    //   setActiveSlideIndex(currentIndex < 0 ? clients.length - 1 : currentIndex);  
    // }

    console.log(swiper.realIndex)
    setActiveSlideIndex(swiper.realIndex);

  };

  const history = useHistory()
  
  const handleSeeMore = (id) => {
    history.push(`/ourworks/${id}`)
  }



  

  return (
    <>
      <Helmet>
				<meta title="Tudo Technologies - Best Mobile App and Website Development Company in Bangalore India with 15 years of experience in web Android and iOS App Development. "
        name="description"
				content="IT Services Company in Bangalore, India, Software companies in Bangalore, iOS/Android development, Software Development, UI/UX Design, D2C app development, Platform development.
				We are dominant IT services company in Bangalore. Which acts as a technology associate partner for you. Avail our custom web development, app solution, iOS/Android Developments and digital marketing solutions that reach to your audience.
				Tudo Technologies is an IT service provider recognized globally as a technology expert with offices in Toronto, Canada, San Francisco, California, USA, Sydney, Australia, Auckland, New Zealand, UAE & Bangalore, India. 
				We offer services in key areas of enterprise mobile app development, customer relationship management (CRM), enterprise content management solutions(CMS), DevOps and software product engineering (SPE). Connect with us and let's discuss business!"
				/>
				<meta name="keywords" content="app development company, top app development companies, app development companies, top app development company, mobile app development company, mobile app development companies, bangalore, 
				bengaluru, dubai, lagos, Abu Dhabi, san francisco, toronto, auckland, sydney, glasgow, UK, USA, Canada, New Zealand, Australia, UAE, India, Nigeria, best app development companies, best app development company"/>
	  	</Helmet>

    <div className=' '>
      {/* <!--=========== Collaborations Section ===========--> */}
      <section className='collaborations'>
        <div className='section-title'>
          <h3>
            Successful Collaborations<span className='cyan'>.</span>
          </h3>
        </div>
        <div className='container'>
          <div className='clients col-lg-12 clients-collab-container' style={{ margin: "0 auto" }}>

            <div className="clients-swiper-container">

            <div className="left-arrow">
              <img class='collab-prev-icon' src='assets/img/prev.svg'/>
            </div>

            <div className="right-arrow">
              <img class='collab-next-icon'  src='assets/img/next.svg'/>
            </div>

            <Swiper
              modules={[Navigation]}
              spaceBetween={30}
              slidesPerView={5}
              centeredSlides={true}
              onSlideChange={(swiper) => handleChangeSlide(swiper)}
              onSwiper={(swiper) => console.log(swiper.activeIndex)}
              loop={true}
              slideToClickedSlide={true}
              navigation={{
                nextEl: ".left-arrow",
                prevEl: ".right-arrow",
              }}
              breakpoints={{

                1200:{
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
              
                500: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                400: {
                  slidesPerView: 3,
                  spaceBetween: 20,
              },
              300: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
                
              }}
            >
              {
                clients.map((item) => (
                  <SwiperSlide>
                    <img src={item.img} alt="" />
                    {
                      width >= 600 &&
                      <p>{item.name}</p>
                    }
                    
                  </SwiperSlide>
                ))
              }
              
            </Swiper>

            </div>

          


            {/* <div className='carousel-wrapper'>
              <div className='carousel d-flex align-items-center'>
                <div title='Royal Brothers'>
                  <a
                    href='https://www.royalbrothers.com/'
                    target='_blank'
                    rel='noreferrer'>
                    <img
                      src='assets/img/clients/client1.png'
                      style={{ width: "150px" }}
                      alt=''
                    />
                  </a>
                </div>
                <div title='Artistic Yoga'>
                  <a
                    href='https://artisticyoga.com/'
                    target='_blank'
                    rel='noreferrer'>
                    <img
                      src='assets/img/clients/client8.png'
                      style={{ width: "150px" }}
                      alt=''
                    />
                  </a>
                </div>
                <div title='Lets Park'>
                  <a
                    href='https://www.letspark.in/'
                    target='_blank'
                    rel='noreferrer'>
                    <img
                      src='assets/img/clients/client7.png'
                      style={{ width: "150px" }}
                      alt=''
                    />
                  </a>
                </div>
                <div title='Humus'>
                  <a
                    href='https://humus.co.in/'
                    target='_blank'
                    rel='noreferrer'>
                    <img
                      src='assets/img/clients/client5.png'
                      style={{ width: "150px" }}
                      alt=''
                    />
                  </a>
                </div>
                <div title='Challo'>
                  <img
                    src='assets/img/clients/client6.png'
                    style={{ width: "150px" }}
                    alt=''
                  />
                </div>
                <div title='Wedding Line'>
                  <img
                    src='assets/img/clients/client4.png'
                    style={{ width: "150px" }}
                    alt=''
                  />
                </div>
                <div title='Enterprise Solution: Meenakshi Logistics'>
                  <a href='https://meenakshilogistics.com/'>
                    <img
                      src='assets/img/clients/client3.png'
                      style={{ width: "150px" }}
                      alt=''
                    />
                  </a>
                </div>

                <div title='Enterprise Solution: Prestige Book House'>
                  <a
                    href='https://g.co/kgs/2ss1GQ'
                    target='_blank'
                    rel='noreferrer'>
                    <img
                      src='assets/img/clients/client2.png'
                      style={{ width: "150px" }}
                      alt=''
                    />
                  </a>
                </div>
              </div>
            </div> */}


            <div className='row about'>
              {/* <p>{clients[activeSlideIndex].content}</p> */}
              <div className='col-lg-5'>
               
                <h1>{clients[activeSlideIndex] && clients[activeSlideIndex].name}</h1>

              </div>
              <div className='col-lg-7'>
                <p>
                {clients[activeSlideIndex] && clients[activeSlideIndex].content}
                {/* <span className="seemore_img" onClick={()=>handleSeeMore(clients[activeSlideIndex].pageId)}>
                  <img src="/assets/rightArrowWhite.svg" alt="" />
                </span> */}
                </p>
                <div className="readmore-arrow" onClick={()=>handleSeeMore(clients[activeSlideIndex].pageId)}>
                  <span>Read more</span>
                  <img src="assets/img/Arrow 5.png" alt="" />
                </div>
              </div>


              
            </div>



            
          </div>
        </div>
      </section>
      {/* <!--=========== End Collaborations Section ===========--> */}
    </div>
    </>
  );
}
