import { useEffect } from "react";
import $ from "jquery";

export default function UseScrolleffect() {
  useEffect(() => {
   
    document.addEventListener("scroll", () => {
      // console.log(window.pageYOffset);
      const effects = document.getElementsByClassName("fadeEntry");
      // console.log(effects);
      [...effects].map((items) => {
        const windowBottom = window.pageYOffset + window.innerHeight * 0.7;
        const itemTop = items.offsetTop;
        // const diff = items.offsetTop - window.pageYOffset;
        // console.log("val=", items.offsetTop, window.pageYOffset, diff);
        if (windowBottom > itemTop) {
          const style = {
            opacity: "1",
            transform: "translate(0,0)",
          };
          Object.assign(items.style, style);
        }
        // else if (windowBottom < itemTop) {
        //   const style = {
        //     opacity: "0",
        //     transform: "translate(0,50px)",
        //   };
        //   Object.assign(items.style, style);
        // }
      });
    });
  }, []);
  return 0;
}
