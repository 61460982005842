import React from "react";
import { Navigation } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// Import Swiper styles
import "swiper/css";

export default function Slide({ data }) {
  const { name, designation, image, testimonial } = data;
  return (
    <>
      <div
        className='d-flex flex-wrap justify-content-between rounded'
        style={{ zIndex: 1, background: "#151515" }}>
        <div className='col-md-5 col-12'>
          <img className='img-fluid' src={image} alt='' srcset='' />
        </div>
        <div className='col-md-7 col-12  d-flex justify-content-center align-items-center px-md-5 px-3'>
          <div className='py-3'>
            <div className='ourSuccess-qoute'>“{testimonial}“</div>
            <div className='mt-md-5 mt-3'>
              <div className='ourSuccess-name'>{name}</div>
              <div className='ourSuccess-company'>{designation}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
