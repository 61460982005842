import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// Import Swiper styles
import "swiper/css";
import "./SuccessStory.css";
import Slide from "./Slide";
import { render } from "@testing-library/react";
import index from "../../../node_modules/resize-observer-polyfill/dist/ResizeObserver.es";
import { Helmet } from "react-helmet";

export default function SuccessStory() {
	const [slideData, setSlideData] = useState([]);
	//   const slideData = [1, 2, 3];
	const [page, setPage] = useState(1);
	const [TotalPage, setTotalPage] = useState(slideData.length);
	// const handleNext = () => {
	// 	if (page === TotalPage) {
	// 		return;
	// 	}
	// 	setPage(page + 1);
	// };
	// const handlePrev = () => {
	// 	if (page === 1) {
	// 		return;
	// 	}
	// 	setPage(page - 1);
	// };
	useEffect(() => {
		fetch("/testimonials.json")
			.then((res) => res.json())
			.then((result) => {
				setSlideData(result);
				setTotalPage(result.length);
			});
	}, []);
	return (

		<>
			<Helmet>
				<meta title="Tudo Technologies - Best Mobile App and Website Development Company in Bangalore India with 15 years of experience in web Android and iOS App Development. "
        		name="description"
				content="IT Services Company in Bangalore, India, Software companies in Bangalore, iOS/Android development, Software Development, UI/UX Design, D2C app development, Platform development.
				We are dominant IT services company in Bangalore. Which acts as a technology associate partner for you. Avail our custom web development, app solution, iOS/Android Developments and digital marketing solutions that reach to your audience.
				Tudo Technologies is an IT service provider recognized globally as a technology expert with offices in Toronto, Canada, San Francisco, California, USA, Sydney, Australia, Auckland, New Zealand, UAE & Bangalore, India. 
				We offer services in key areas of enterprise mobile app development, customer relationship management (CRM), enterprise content management solutions(CMS), DevOps and software product engineering (SPE). Connect with us and let's discuss business!"
				/>
				<meta name="keywords" content="app development company, top app development companies, app development companies, top app development company, mobile app development company, mobile app development companies, bangalore, 
				bengaluru, dubai, lagos, Abu Dhabi, san francisco, toronto, auckland, sydney, glasgow, UK, USA, Canada, New Zealand, Australia, UAE, India, Nigeria, best app development companies, best app development company"/>
	  		</Helmet>
		
		<section className='fadeEntry animate__animated'>
			<div className='container my-5'>
				<h1 className='ourSuccess-title'>
					Our Success Stories<span className='cyan'>.</span>
				</h1>
				<div className='my-5 position-relative'>
					<Swiper
						modules={[Navigation]}
						spaceBetween={5}
						slidesPerView={1}
						onSlideChange={(swiper) =>
							setPage(swiper.realIndex + 1)
						}
						navigation={{
							nextEl: ".next-btn",
							prevEl: ".prev-btn",
						}}
						className='mySwiper'>
						{slideData.map((items, index) => (
							<SwiperSlide>
								<Slide key={index} data={items} />
							</SwiperSlide>
						))}
					</Swiper>
					<div
						className='d-flex justify-content-md-end align-items-center my-3'
						style={{ zIndex: 50 }}>
						<span
							// onClick={handlePrev}
							className='prev-btn'
							style={{ cursor: "pointer" }}>
							<img
								className=''
								src='/assets/img/Arrow 5.png'
								alt=''
								srcset=''
							/>
						</span>
						<span
							// onClick={handleNext}
							className='next-btn mx-3'
							style={{ cursor: "pointer" }}>
							<img
								className='img-fluid'
								src='/assets/img/Arrow 6.png'
								alt=''
								srcset=''
							/>
						</span>
						<span className='ourSuccess-pagination'>
							{page} / {TotalPage}
						</span>
					</div>
				</div>
			</div>
		</section>
		</>
	);
}
